import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import useMediaQuery from "@mui/material/useMediaQuery";
import { format, set } from "date-fns";
import { FormEvent, JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Currency, Referral, User, UserStatus } from "../../__generated__/graphql";
import VerificationImg from "../../assets/svgs/7030148_security_locked_ui basic_lock_protection_icon.svg";
import { GlobalErrorHandler } from "../../components/error/global_error.component";
import { Modal } from "../../components/modal/modal";
import { PaymentMethod } from "../../components/payment_method/payment_method";
import { Sidebar } from "../../components/sidebar/sidebar";
import TransactionsSkeleton from "../../components/skeleton/transactionskeleton";
import VaultSkeleton from "../../components/skeleton/vaultskeleton";
import { DEPOSIT, DEPOSIT_CRYPTO } from "../../graphql/mutations/deposit";
import { LIST_PAYMENT_METHODS } from "../../graphql/mutations/list_payment_methods";
import { GET_BALANCE } from "../../graphql/queries/get-balance";
import { QUERY_GET_CURRENCIES } from "../../graphql/queries/get-currency";
import { QUERY_GET_ME } from "../../graphql/queries/get-me";
import { QUERY_GET_SUMSUB_WEB_SDK_LINK } from "../../graphql/queries/get-sumsub-link";
import { GET_TRANSACTIONS } from "../../graphql/queries/get-transactions";
import { toCurrencyDisplay } from "../../utils/currency.util";
import { useAuthAndErrorHandling } from "../../utils/invalid-token.util";
import ImportantNoticeImg from "../../assets/svgs/shield-waring.svg";
import { ConfirmationModal } from "../../components/modal/confirm-modal";
import { DETACH_PAYMENT_METHOD } from "../../graphql/mutations/vault";
import UnderMaintenance from "../../components/modal/under-maintenance";
import { KycCheck } from "../account/kyc/kyc";
import { GET_REFERRALS_BY_REFERRER } from "../../graphql/queries/coupon";
import { ReactComponent as ShareLogo } from '../../assets/svgs/share_arrow.svg';
import { ReactComponent as Coupon } from '../../assets/svgs/coupon.svg';
import { REDEEM_COUPON } from "../../graphql/mutations/coupon";
import { QUERY_GET_SYS_CONFIG } from "../../graphql/queries/get-config";
import { processSysConfigs } from "../../utils/config.util";

const useResponsiveSize = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  return {
    width: isSmallScreen ? 300 : 700,
    height: isSmallScreen ? 275 : 550,
  };
};

export function VaultPage() {
  const responsiveSize = useResponsiveSize();
  const navigate = useNavigate();
  const [iframeUrl, setIframeUrl] = useState<string>("");
  const [intervalId, setIntervalId] = useState<NodeJS.Timeout | null>(null);
  const [backgroundRefresh, setBackgroundRefresh] = useState<boolean>(false);

  const {
    data: user,
    loading,
    error,
    refetch,
  } = useQuery<{ me: User }>(QUERY_GET_ME, { fetchPolicy: "network-only" });
  const [listPaymentMethods, { loading: loadingPaymentMethods, data: paymentMethodData, error: paymentMethodError, },] = useLazyQuery(LIST_PAYMENT_METHODS, { fetchPolicy: "network-only" });
  const [getTransactions, { loading: loadingTransactions, data: transactionData, error: transactionError, startPolling: startTransactionPolling, stopPolling: stopTransactionPolling }] = useLazyQuery(GET_TRANSACTIONS, { fetchPolicy: "network-only" });
  const [getBalance, { loading: loadingBalance, data: balanceData, error: getBalanceError, startPolling: startBalancePolling, stopPolling: stopBalancePolling }] = useLazyQuery(GET_BALANCE, { fetchPolicy: "network-only" });
  const [getcurrencies, { loading: loadingCurrencies, data: currenciesData, error: currenciesError, },] = useLazyQuery(QUERY_GET_CURRENCIES, { fetchPolicy: "network-only" });
  const [deposit, { loading: loadingDeposit, data: depositData, error: depositError },] = useMutation(DEPOSIT, { fetchPolicy: "network-only" });
  const [depositCrypto, { loading: loadingDepositCrypto, data: depositCryptoData, error: depositCryptoError, },] = useMutation(DEPOSIT_CRYPTO, { fetchPolicy: "network-only" });
  const [detachPaymentMethod, { loading: detachPaymentMethodLoading, }] = useMutation(DETACH_PAYMENT_METHOD);
  const [getSumsubWebSdkLink, { data: sumsubVerifyUrl, loading: sumsubLoading, error: sumsubError },] = useLazyQuery(QUERY_GET_SUMSUB_WEB_SDK_LINK, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data && data.sumsubWebSdkLink) {
        setIframeUrl(data.sumsubWebSdkLink);
      }
    },
  });
  const { data: referralData } = useQuery(GET_REFERRALS_BY_REFERRER, { fetchPolicy: "network-only" });
  const [redeemCoupon] = useMutation(REDEEM_COUPON, { fetchPolicy: "network-only" });
  const { data: configData } = useQuery(QUERY_GET_SYS_CONFIG, {
    fetchPolicy: "network-only",
  });
  const [canDepositBeforeKyc, setCanDepositBeforeKyc] = useState(true);
  const [minDepositToken, setMinDepositToken] = useState(0);


  useEffect(() => {
    if (configData) {
      const processedConfigs = processSysConfigs(configData.getAllConfigs);

      setCanDepositBeforeKyc(processedConfigs.canDepositBeforeKyc);
      setMinDepositToken(processedConfigs.minDepositToken);
    }
  }, [configData]);

  useEffect(() => {
    const url = window.location.href;
    const hasVerifyParam = url.includes('#verify') || url.includes('?verify');

    if (hasVerifyParam && user && user.me?.status === UserStatus.Unverified) {
      setGoTOVerification(true);
    }
  }, [user]);

  const [showCurrencySelector, setShowCurrencySelector] = useState(false);
  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState<string | null>(null);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState<string>("1");
  const [ccFee, setCCFee] = useState<number>(0);
  const [totalBalance, setTotalBalance] = useState<string>("0");
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<Currency | null>(
    null
  );
  const [depositMethod, setDepositMethod] = useState<string>();
  const [infoMessage, setInfoMessage] = useState<string | null>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [goTOVerification, setGoTOVerification] = useState<boolean>(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [showReferrals, setShowReferrals] = useState(false);
  const [showRedeemRewards, setShowRedeemRewards] = useState(false);
  const [redeemAmount, setRedeemAmount] = useState(100);
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [redeemError, setRedeemError] = useState<string | null>(null);

  const rewardBalance = user?.me.investorProfile?.rewardBalance;

  const handleAmountChange = (increment: number) => {
    const newAmount = redeemAmount + increment;
    if (newAmount >= 100 && newAmount <= rewardBalance!) {
      setRedeemAmount(newAmount);
    }
  };

  useAuthAndErrorHandling(error);
  useAuthAndErrorHandling(paymentMethodError);
  useAuthAndErrorHandling(transactionError);
  useAuthAndErrorHandling(getBalanceError);
  useAuthAndErrorHandling(currenciesError);
  useAuthAndErrorHandling(sumsubError);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  useEffect(() => {
    if (paymentMethodData && paymentMethodData.listPaymentMethods.length > 0) {
      setSelectedPaymentMethod(paymentMethodData.listPaymentMethods[0].id);
    }
  }, [paymentMethodData]);

  useEffect(() => {
    setIsInitialLoad(loadingBalance); // Will be true only during initial load
  }, [loadingBalance]);

  useEffect(() => {
    setTotalBalance(depositAmount);
    if (!isNaN(parseInt(depositAmount))) {
      setCCFee(parseInt(depositAmount) * 100 * 0.025); // 2.5% fee{
    } else {
      setCCFee(0);
    }
  }, [depositAmount]);

  useEffect(() => {
    listPaymentMethods();
    getTransactions();
    getBalance();
    getcurrencies();
  }, []);

  useEffect(() => {
    if (iframeUrl && user && user.me.status !== UserStatus.Active) {
      const id = setInterval(() => {
        refetch(); // Re-fetch user data every 2 seconds
      }, 2000);

      setIntervalId(id);

      return () => {
        clearInterval(id); // Clean up interval when component unmounts or conditions change
      };
    }

    // If iframeUrl is not present or status is Active, clear the interval
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }
  }, [iframeUrl, user?.me.status, refetch]);

  // When we redirect from stripe-redirect to this page, then refresh getTransaction and getBalance after every 2 secs for 5 times
  useEffect(() => {
    let count = 0;
    const interval = setInterval(async () => {
      setBackgroundRefresh(true);
      count++;
      await getTransactions();
      await getBalance();
      if (count >= 5) {
        setBackgroundRefresh(false);
        clearInterval(interval);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [window.location.pathname]);

  useEffect(() => {
    setErrorMessage(depositError?.message);
  }, [depositError]);

  useEffect(() => {
    if (selectedCurrency !== null) {
      setDepositAmount("0");
    } else {
      setDepositAmount("1");
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (depositData) {
      if (!infoMessage) {
        setShowDepositModal(false);
      }
      getTransactions();
      getBalance();
    }
    if (depositCryptoData) {
      getTransactions();
      getBalance();
    }
  }, [depositData, depositCryptoData]);

  // Function to handle polling logic
  const startTransactionAndBalancePolling = () => {
    // Start polling for both transactions and balance
    startTransactionPolling(2000); // Poll every 2 seconds
    startBalancePolling(2000);     // Poll every 2 seconds
  };

  const stopTransactionAndBalancePolling = () => {
    stopTransactionPolling(); // Stop polling transactions
    stopBalancePolling();     // Stop polling balance
  };

  useEffect(() => {
    if (balanceData) {
      startTransactionAndBalancePolling();

      // Set a timer to stop polling after 15 seconds (15000 ms)
      const timerId = setTimeout(() => {
        stopTransactionAndBalancePolling();
      }, 15000);

      // Cleanup the timer if the component unmounts or balanceData changes
      return () => clearTimeout(timerId);
    }
  }, [depositData, depositCryptoData]);

  useEffect(() => {
    // Cleanup polling when the component unmounts
    return () => {
      stopTransactionAndBalancePolling();
    };
  }, []);

  function handlePaymentMethodClick(payment_method_id: string) {
    if (showCurrencySelector === false) {
      setSelectedCurrency(null);
      setSelectedPaymentMethod(payment_method_id);
    } else {
      setShowCurrencySelector(false);
      setSelectedCurrency(null);
      setSelectedPaymentMethod(payment_method_id);
    }
  }

  function handleSumsubVerification() {
    getSumsubWebSdkLink();
  }

  function renderPaymentMethod(paymentMethod: any) {
    switch (paymentMethod.type) {
      case "card":
        return (
          <div
            key={paymentMethod.id}
            className="relative card"
            style={{ flex: "1 1 40%" }
            }
          >
            <ConfirmationModal
              id={paymentMethod.id}
              isOpen={isModalOpen}
              onClose={() => setModalOpen(false)}
              onConfirm={handleDelete}
              loading={detachPaymentMethodLoading}
            />
            <div
              data-test="card-delete"
              className="absolute -top-1 -right-1 cursor-pointer transition ease-in duration-100 rounded-full p-2 pb-2.5 bg-[#D9DBE0] text-gray-500 hover:text-black hover:bg-red-500 flex items-center justify-center"
              style={{ width: '24px', height: '24px' }}
              onClick={() => setModalOpen(true)}
            >
              x
            </div>
            <div>
              <div className="flex justify-between">
                <p>Type</p>
                <p data-test="type" className="font-semibold">Card</p>
              </div>
              <div className="flex justify-between">
                <p>Brand</p>
                <p data-test="brand" className="font-semibold">
                  {paymentMethod.card.display_brand?.toUpperCase()}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Expiration</p>
                <p data-test="expiration" className="font-semibold">
                  {paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Last 4 digits</p>
                <p data-test="last-4-digits" className="font-semibold">{paymentMethod.card.last4}</p>
              </div>
            </div>
          </div>
        );
      case "sepa_debit":
        return (
          <div
            key={paymentMethod.id}
            className="card"
            style={{ flex: "1 1 40%" }}
          >
            <div className="flex justify-between">
              <p>Type</p>
              <p data-test="sepa-type" className="font-semibold">SEPA Debit</p>
            </div>
            <div className="flex justify-between">
              <p>Bank code</p>
              <p data-test="sepa-bank-code" className="font-semibold">
                {paymentMethod.sepa_debit.bank_code}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Country</p>
              <p data-test="sepa-country" className="font-semibold">
                {paymentMethod.sepa_debit.country}
              </p>
            </div>
            <div className="flex justify-between">
              <p>Last 4 digits</p>
              <p data-test="sepa-4-digits" className="font-semibold">{paymentMethod.sepa_debit.last4}</p>
            </div>
          </div>
        );
    }
  }

  function renderPaymentMethodForDeposit(paymentMethod: any) {
    switch (paymentMethod.type) {
      case "card":
        return (
          <div
            key={paymentMethod.id}
            data-test="select-card"
            className={`card flex justify-around selectable-payment-method ${paymentMethod.id === selectedPaymentMethod &&
              showCurrencySelector === false
              ? " selected"
              : ""
              }`}
            onClick={() => handlePaymentMethodClick(paymentMethod.id)}
          >
            <p className="font-semibold">
              {paymentMethod.card.display_brand.toUpperCase(0)}
            </p>
            <p className="font-semibold">Card</p>
            <p data-test="expiration" className="font-semibold">
              {paymentMethod.card.exp_month} / {paymentMethod.card.exp_year}
            </p>
            <p className="font-semibold">{paymentMethod.card.last4}</p>
          </div>
        );
      case "sepa_debit":
        return (
          <div
            key={paymentMethod.id}
            data-test="select-card"
            className={`card flex justify-around selectable-payment-method ${paymentMethod.id === selectedPaymentMethod &&
              showCurrencySelector === false
              ? " selected"
              : ""
              }`}
            onClick={() => handlePaymentMethodClick(paymentMethod.id)}
          >
            <p className="font-semibold">SEPA Debit</p>
            <p className="font-semibold">{paymentMethod.sepa_debit.country}</p>
            <p className="font-semibold">{paymentMethod.sepa_debit.last4}</p>
          </div>
        );
    }
  }
  function handleCurrencyChange(event: React.ChangeEvent<HTMLSelectElement>) {
    const selectedCurrencyId = event.target.value;
    if (selectedCurrencyId === "") {
      setSelectedCurrency(null);
    } else {
      const selectedCurrency =
        currenciesData?.currencies.find(
          (currency: Currency) => currency.id === selectedCurrencyId
        ) || null;
      setSelectedCurrency(selectedCurrency);
      setSelectedPaymentMethod("crypto");
    }
  }

  function doDeposit(e: FormEvent<HTMLButtonElement>) {
    e.preventDefault();

    if (
      user &&
      user.me.status === UserStatus.Unverified &&
      !canDepositBeforeKyc &&
      parseInt(depositAmount) + balanceData.getBalanceObject.getBalance > minDepositToken
    ) {
      setErrorMessage(
        `Please verify your account to deposit more than ${minDepositToken} token`
      );
      return;
    }

    if (!selectedPaymentMethod) {
      return;
    }

    const depositAmountInt = parseInt(depositAmount);
    if (depositAmountInt <= 0) {
      setErrorMessage("Please enter at least 1 token");
      setDepositAmount("1");
      return;
    }

    if (depositAmountInt > 10) {
      setInfoMessage(
        "Our sales team has been notified and will contact you soon regarding your deposit. Deposits over £1000 require additional follow-up to ensure a smooth and efficient process. Thank you for your patience."
      );
    }

    if (selectedPaymentMethod === "crypto") {
      depositCrypto({
        variables: {
          amount: depositAmountInt,
          currency: selectedCurrency?.name,
        },
      });
    } else {
      deposit({
        variables: {
          paymentMethodId: selectedPaymentMethod,
          amount: depositAmountInt * 100 * 100, // convert to cents
          currency: "gbp",
        },
      });
    }

  }

  const handleDelete = async (id: string) => {
    try {
      const { data } = await detachPaymentMethod({
        variables: { paymentMethodId: id },
      });
      console.log(`Deleted payment method with ID: ${id}`, data);
      listPaymentMethods();
    } catch (error) {
      console.error("Error deleting payment method:", error);
    }
  };

  useEffect(() => {
    if (depositData && depositData.depositFiat?.id) {
      const { status, next_action } = depositData.depositFiat;

      if (status === "requires_action" && next_action && next_action.url) {
        // If next_action contains a URL, navigate to the 3D Secure redirect
        window.open(next_action.url, "_blank");
      }
    }
  }, [depositData, navigate]);

  if (user && user.me.status === UserStatus.Unverified && goTOVerification) {
    return (
      <>
        <GlobalErrorHandler />
        <Sidebar />
        {iframeUrl && (
          <div className="p-4 content">
            <iframe
              src={iframeUrl}
              title="Sumsub Verification"
              width="100%"
              height={800}
              allow="camera; microphone"
            ></iframe>
          </div>
        )}
        {!iframeUrl && (
          <section className="content vault">
            <h1 data-test="vault-heading" className="mb-8 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">
              Vault
            </h1>
            <div className="flex flex-col flex-wrap items-center justify-between p-12 mt-8 mb-8 align-middle bg-white border-gray-200 rounded-lg">
              <img
                src={VerificationImg}
                alt="Verification"
                className="w-20 h-20"
              />
              <p className="p-4 text-2xl font-bold">Verify to invest</p>
              <p className="max-w-md p-4 mb-2 text-lg text-center ">
                Complete your KYC verification to unlock the ability to deposite
                funds and start investing. This ensures the security and
                integrity of our platform.{" "}
              </p>
              <div
                className="p-8 btn"
                onClick={() => handleSumsubVerification()}
              >
                Start verification
              </div>
            </div>
          </section>
        )}
      </>
    );
  }

  const handleCloseUnderMaintenance = () => {
    setErrorMessage('');
  }

  if (user && user.me.status === UserStatus.VerificationInProgress) {
    return (
      <>
        <GlobalErrorHandler />
        <UnderMaintenance
          errorType={errorMessage}
          onClose={handleCloseUnderMaintenance}
        />
        <Sidebar />
        <section className="content vault">
          <h1 className="mb-8 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">
            Vault
          </h1>
          <div className="flex flex-col flex-wrap items-center justify-between p-12 mt-8 mb-8 align-middle bg-white border-gray-200 rounded-lg">
            <img
              src={VerificationImg}
              alt="Verification"
              className="w-20 h-20"
            />
            <p className="p-4 text-2xl font-bold">Verification in Progress</p>
            <p className="max-w-md p-4 mb-2 text-lg text-center ">
              Your application is being reviewed to comply with our KYC
              requirements. You will be able to deposit money and start
              investing with PropNerd once your verification is complete.
            </p>
            <p className="max-w-md p-4 mb-2 text-lg text-center ">
              An email will be sent to you upon completion of the verification
              process.
            </p>
          </div>
        </section>
      </>
    );
  }
  if (user && user.me.status === UserStatus.Blocked) {
    return (
      <>
        <GlobalErrorHandler />
        <Sidebar />
        <section className="content vault">
          <h1 className="mb-8 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">
            Vault
          </h1>
          <div className="flex flex-col flex-wrap items-center justify-between p-12 mt-8 mb-8 align-middle bg-white border-gray-200 rounded-lg">
            <img
              src={VerificationImg}
              alt="Verification"
              className="w-20 h-20 tint-[#ff0000]"
            />
            <p className="p-4 text-2xl font-bold text-red">Account Blocked</p>
            <p className="max-w-md p-4 mb-2 text-lg text-center ">
              Unfortunately, your KYC verification did not meet our
              requirements. You currently will not be able to deposit money or
              invest with PropNerd.
            </p>
            <p className="max-w-md p-4 mb-2 text-lg text-center ">
              Please contact support at info@propnerd.io for further assistance.
            </p>
          </div>
        </section>
      </>
    );
  }

  const redeemRewards = async () => {
    try {
      await redeemCoupon({ variables: { amount: redeemAmount } });
      setErrorMessage('');
      setIsRedeemed(true);
      refetch();
    } catch (error: any) {
      setRedeemError(error.message);
    }
  }

  console.log("depositAmount", depositAmount)
  console.log("balanceData?.getBalanceObject.getBalance", balanceData?.getBalanceObject.getBalance)
  console.log("balanceData?.getBalanceObject.totalDepositSum", balanceData?.getBalanceObject.totalDepositSum)
  console.log("minDepositToken", minDepositToken)

  return (
    <>
      <KycCheck />
      <GlobalErrorHandler />

      <Sidebar />

      {showPaymentModal && (
        <PaymentMethod
          onClose={() => {
            setShowPaymentModal(false);
            listPaymentMethods();
          }}
        />
      )}

      {showDepositModal && (
        <Modal
          title="Deposit"
          onClose={() => {
            setShowDepositModal(false);
            setInfoMessage(null);
            setDepositAmount("1");
            setSelectedPaymentMethod(paymentMethodData?.listPaymentMethods[0]?.id)
          }}
        >

          {!infoMessage && (
            <form>
              {!depositCryptoData && (
                <>
                  <div className="flex items-center text-center">
                    <div className="p-4 text-center rounded-l-lg bg-light-grey">
                      {selectedCurrency === null ||
                        showCurrencySelector === false
                        ? "Tokens"
                        : selectedCurrency?.name}
                    </div>
                    <input
                      type="number"
                      value={depositAmount}
                      data-test="tokens"
                      min={"1"}
                      onChange={(e) => setDepositAmount(e.target.value)}
                      className="w-full px-6 py-3 text-lg font-semibold border rounded-r-lg input-control"
                    />
                  </div>
                  <div className="p-4 mt-2 mb-2 rounded-lg payment-breakdown bg-gray-50">
                    <h2 className="mb-2 text-sm font-bold text-deep-blue">
                      Payment Breakdown
                    </h2>
                    <p className="mb-1 text-xs">
                      Base Amount:{" "}
                      <span data-test="base-amount" className="text-xs font-bold">
                        £
                        {!isNaN(parseInt(depositAmount) * 100)
                          ? parseInt(depositAmount) * 100
                          : 0}
                      </span>
                    </p>
                    <p className="mb-1 text-xs">
                      Credit Card Fee:{" "}
                      <span data-test="fee-amount" className="text-xs font-bold">£{ccFee}</span>
                    </p>
                    <hr className="my-2" />
                    <p className="text-xs">
                      Total Amount:
                      <span data-test="total-amount" className="text-xs font-bold">
                        {"£" +
                          (!isNaN(parseInt(depositAmount) * 100)
                            ? parseInt(depositAmount) * 100 + ccFee
                            : 0)}
                      </span>
                    </p>
                    <p className="mt-2 text-xs text-gray-600">
                      This total includes the deposit amount and the credit card
                      fee.
                    </p>
                  </div>
                  <br />
                  {showCurrencySelector && (
                    <select
                      className="w-full p-4 mb-4 font-light border rounded-md "
                      value={selectedCurrency?.id || ""}
                      onChange={handleCurrencyChange}
                    >
                      <option value={""}>Select Currency</option>
                      {currenciesData?.currencies.map((currency: Currency) => (
                        <option key={currency.id} value={currency.id}>
                          {currency.name}
                        </option>
                      ))}
                    </select>
                  )}
                  <div className="flex justify-between gap-4 mb-6">
                    <div className="w-1/2 p-4 border rounded-md">
                      <p className="font-light">Current Tokens</p>
                      <p data-test="current-tokens" className="text-lg font-semibold">
                        {balanceData?.getBalanceObject.getBalance / 100 / 100}
                      </p>
                    </div>
                    {!showCurrencySelector && (
                      <div className="w-1/2 p-4 border rounded-md">
                        <p className="font-light">New Tokens</p>
                        <p data-test="new-tokens" className="text-lg font-semibold">
                          {parseInt(totalBalance) || ""}
                        </p>
                      </div>
                    )}
                  </div>

                  {paymentMethodData &&
                    paymentMethodData.listPaymentMethods.length === 0 && (
                      <div className="rounded-lg border-1.5 border-[#F8D5AE] max-w-md bg-[#FDF5EC]  p-4 flex flex-col gap-8 mb-6">
                        <div className="flex gap-2">
                          <img
                            src={ImportantNoticeImg}
                            alt="Shield warning"
                          />{" "}
                          <p className="font-bold text-[#14223D] ">
                            Important Note
                          </p>{" "}
                        </div>
                        <div className="flex-col text-center">
                          <p className="text-[#768195] mb-2">
                            You need to add a card or bank account first before you
                            can deposit an amount into your vault
                          </p>
                          <div
                            className="p-6 btn"
                            onClick={() => { setShowDepositModal(false); setShowPaymentModal(true); }}
                          >
                            Add New Card
                          </div>
                        </div>
                      </div>
                    )}
                  {paymentMethodData &&
                    paymentMethodData.listPaymentMethods.length > 0 && (
                      <>
                        <p className="mb-2 font-semibold text-center">
                          Choose a payment method
                        </p>
                        <div
                          className="flex flex-col gap-4 mb-6"
                          data-test="cards"
                          style={{ maxHeight: "300px", overflowY: "auto" }}
                        >
                          {paymentMethodData &&
                            paymentMethodData.listPaymentMethods.length > 0 &&
                            paymentMethodData.listPaymentMethods.map(
                              renderPaymentMethodForDeposit
                            )}
                        </div>
                        {/* <div className= {`card flex justify-around selectable-payment-method mb-6 ${showCurrencySelector === true ? ' selected' : ''}`} onClick={() =>{
                if(selectedPaymentMethod !== '' && showCurrencySelector === false){
                  setSelectedPaymentMethod('');
                }
                if(showCurrencySelector){
                  setSelectedCurrency(null)
                }
                setShowCurrencySelector(!showCurrencySelector)
              }}>
                <p className="font-semibold">Pay via Cryptocurrency</p>
              </div> */}

                        {/* important note */}
                        <div className="rounded-lg border-1.5 border-[#F8D5AE] max-w-md bg-[#FDF5EC]  p-4 flex flex-col gap-8 mb-6">
                          <div className="flex gap-2">
                            <img
                              src={ImportantNoticeImg}
                              alt="Shield warning"
                            />{" "}
                            <p data-test="important-message" className="font-bold text-[#14223D] ">
                              Important Note
                            </p>{" "}
                          </div>
                          {user &&
                            user.me.status === UserStatus.Active &&
                            (parseInt(depositAmount) <= 10 ||
                              depositAmount === "" ? (
                              <p className="text-[#768195]">
                                Once deposited,{" "}
                                <span className="text-[#14223D] font-bold ">
                                  tokens are non-refundable
                                </span>{" "}
                                due to the immutable nature of blockchain
                                transactions. All deposits and investments are{" "}
                                <span className="font-bold text-[#14223D]">
                                  {" "}
                                  secured on Solana's blockchain
                                </span>
                                , ensuring{" "}
                                <span className="font-bold text-[#14223D]">
                                  transparency
                                </span>{" "}
                                and{" "}
                                <span className="font-bold text-[#14223D]">
                                  security
                                </span>
                                .
                              </p>
                            ) : (
                              <p className="text-[#768195]">
                                <span className="font-bold text-[#14223D]">
                                  Deposits over £1000
                                </span>{" "}
                                require additional follow-up to ensure a{" "}
                                <span className="font-bold text-[#14223D]">
                                  smooth and efficient process.
                                </span>{" "}
                                Our sales team will be notified on deposits over
                                £1,000 and they will get{" "}
                                <span className="font-bold text-[#14223D]">
                                  in touch with you
                                </span>{" "}
                              </p>
                            ))}

                          {user &&
                            user.me.status === UserStatus.Unverified &&
                            (parseInt(depositAmount) +
                              balanceData?.getBalanceObject.getBalance / 100 / 100 > minDepositToken
                              ||
                              balanceData?.getBalanceObject.totalDepositSum >= minDepositToken ? (
                              <div className="flex-col text-center">
                                <p className="text-[#768195] mb-2">
                                  To deposit more than{" "}
                                  <span className="text-[#14223D] font-bold ">
                                    £{minDepositToken * 100}
                                  </span>
                                  , we require you to complete our{" "}
                                  <span className="text-[#14223D] font-bold ">
                                    KYC verification
                                  </span>
                                  . This quick process ensures your account's
                                  security and complies with regulations,
                                  allowing you to seamlessly invest with peace
                                  of mind.
                                </p>
                                <div
                                  className="p-6 btn"
                                  onClick={() => setGoTOVerification(true)}
                                >
                                  Verify Now
                                </div>
                              </div>
                            ) : (
                              <p className="text-[#768195]">
                                Once deposited,{" "}
                                <span className="text-[#14223D] font-bold ">
                                  tokens are non-refundable
                                </span>{" "}
                                due to the immutable nature of blockchain
                                transactions. All deposits and investments are{" "}
                                <span className="font-bold text-[#14223D]">
                                  {" "}
                                  secured on Solana's blockchain
                                </span>
                                , ensuring{" "}
                                <span className="font-bold text-[#14223D]">
                                  transparency
                                </span>{" "}
                                and{" "}
                                <span className="font-bold text-[#14223D]">
                                  security
                                </span>
                                .
                              </p>
                            ))}
                        </div>

                        {infoMessage && (
                          <div className="max-w-md px-12 py-6 mb-12 border rounded-lg bg-light-grey">
                            {infoMessage}
                          </div>
                        )}
                        {errorMessage && errorMessage !== 'UNDER_MAINTENANCE' && (
                          <div className="max-w-md px-12 py-6 mb-12 text-red-500 border rounded-lg bg-light-grey -600">
                            {errorMessage}
                          </div>
                        )}

                        <button
                          className="w-full py-6 btn"
                          data-test="deposit-btn-2"
                          title={
                            !selectedPaymentMethod
                              ? "Select a payment method"
                              : ""
                          }
                          onClick={doDeposit}
                          disabled={
                            loadingDeposit ||
                            balanceData.getBalanceObject.totalDepositSum >= minDepositToken && (user && user.me.status === UserStatus.Unverified && canDepositBeforeKyc) ||
                            loadingDepositCrypto ||
                            !selectedPaymentMethod ||
                            (user &&
                              user.me.status === UserStatus.Unverified &&
                              parseInt(depositAmount) +
                              balanceData?.getBalanceObject.getBalance / 100 / 100 > minDepositToken)
                          }
                        >
                          {loadingDeposit || loadingDepositCrypto
                            ? "Depositing..."
                            : "Deposit"}
                        </button>
                      </>
                    )}
                </>
              )}
              {!loadingDepositCrypto && depositCryptoData && (
                <p className="max-w-md p-4 m-4 text-center border rounded-md ">
                  Please deposit {depositAmount} {selectedCurrency?.name} into
                  this wallet account:
                  <br />
                  <p className="font-bold text-wrap px-5 py-2.5 bg-gray-200 rounded-md m-2 break-words">
                    {depositCryptoData.depositCrypto.cryptoAddress}
                  </p>
                  {depositCryptoData.depositCrypto.tag && (
                    <>
                      <p> with tag:</p>
                      <p className=" font-bold break-words px-5 py-2.5  m-2 rounded-md  bg-gray-200">
                        {depositCryptoData.depositCrypto.tag}
                      </p>
                    </>
                  )}
                  <br />
                  Once deposited, the amount will be converted to PropNerd
                  tokens and be ready for investing.
                </p>
              )}
            </form>
          )}

          {infoMessage && (
            <div className="max-w-md">
              <p data-test="info-message" className="px-12 py-6 mb-4 border rounded-lg bg-light-grey">
                {infoMessage}
              </p>
              <button
                className="w-full py-2 text-lg text-center text-white border rounded-md bg-dark-blue hover:bg-deep-blue"
                data-test="ok-btn"
                onClick={() => {
                  setShowDepositModal(false);
                  setInfoMessage(null);
                }}
              >
                Ok
              </button>
            </div>
          )}

        </Modal>
      )}

      <section className="content vault">
        <h1 data-test="vault-heading" className="mb-8 font-sans text-3xl font-bold leading-normal tracking-normal text-deep-blue">
          Vault
        </h1>
        <div className="flex flex-row flex-wrap justify-between gap-6 m-flex-col">
          {loadingBalance && !backgroundRefresh ? (
            <VaultSkeleton width={responsiveSize.width} height={200} />
          ) : (
            <div className="flex flex-col flex-1 gap-4 card md:flex-row">
              <div className="flex flex-col justify-around flex-3">
                <p className="text-lg text-gray-800">Token Value</p>
                <h3 data-test="token-value" className="font-sans text-4xl font-bold leading-normal tracking-normal text-deep-blue">
                  {toCurrencyDisplay(balanceData?.getBalanceObject.getBalance)}
                </h3>
              </div>
              <div className="flex flex-col flex-1 gap-2">
                <a
                  href="#deposit"
                  className="py-6 btn btn-primary"
                  data-test="deposit-btn-1"
                  onClick={() => {
                    setDepositAmount("1");
                    setSelectedPaymentMethod(paymentMethodData?.listPaymentMethods[0]?.id);
                    setShowDepositModal(true)
                  }}
                >
                  Deposit
                </a>
                <a data-test="withdraw-btn-1" href="#withdraw" className="py-6 btn btn-secondary">
                  Withdraw
                </a>
              </div>
            </div>
          )}
        </div>

        <div className="mt-8 card">
          <div className="flex gap-4">
            <div className="flex flex-col w-3/4">
              <h4 className="text-2xl font-bold text-gray-800">Rewards & Referrals</h4>
              <p className="mt-2 text-lg font-medium text-gray-700">
                Share your exclusive coupon code with friends and earn rewards! For every successful investment through your referral, you’ll earn £25 as a thank-you.
              </p>
              <div className="flex gap-4 mt-6">
                <button
                  className="flex gap-2 py-6 font-semibold btn btn-primary"
                  onClick={() => { setShowReferrals(!showReferrals); setIsRedeemed(false) }}
                >
                  View Referred Friends
                  <ShareLogo />
                </button>
                {user?.me.investorProfile?.rewardBalance && user?.me.investorProfile?.rewardBalance > 0 ?
                  <button
                    className="py-6 [&:not(:hover)]:!bg-transparent btn btn-secondary"
                    onClick={() => setShowRedeemRewards(!showRedeemRewards)}
                  >
                    Redeem Rewards
                  </button>
                  : null}
              </div>
            </div>
            <div className="flex flex-col justify-end w-1/4 pl-10 space-y-6">
              <div className="flex flex-col gap-2">
                <span className="text-xs font-extrabold text-deep-blue">Your Coupon Code</span>
                <div className="flex items-center px-4 py-2 border rounded-xl border-deep-blue">
                  <span className="pr-2 text-gray-500">
                    <Coupon />
                  </span>
                  <span className="mx-2 text-[#DDD]">|</span>
                  <input
                    type="text"
                    value={user?.me.investorProfile?.coupon?.code}
                    readOnly
                    className="w-full text-sm font-semibold bg-transparent outline-none text-deep-blue"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-2">
                <span className="text-xs font-extrabold text-deep-blue">Your Reward Balance</span>
                <div className="flex items-center px-4 py-2 border rounded-xl border-deep-blue">
                  <span className="pr-2 text-sm font-semibold text-deep-blue">£</span>
                  <span className="mx-2 text-[#DDD]">|</span>
                  <input
                    type="text"
                    value={rewardBalance ?? ''}
                    readOnly
                    className="w-full text-sm font-semibold bg-transparent outline-none text-deep-blue"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        {showReferrals && (
          <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="w-2/5 px-6 py-10 bg-white rounded-lg shadow-lg">
              <div className="flex items-center justify-between mb-4 pb-4 border-b border-[#E5E5E5]">
                <h2 className="text-xl font-bold text-deep-blue">Your Referrals</h2>
                <button onClick={() => setShowReferrals(false)} className="text-2xl text-gray-500 text- hover:text-gray-800">
                  &times;
                </button>
              </div>
              <div>
                <p className="mb-2 text-sm font-bold text-deep-blue">Invite Email</p>
                {/* <div className="flex items-center mb-6 space-x-4">
                  <input
                    type="email"
                    placeholder="Enter Email Address"
                    className="flex-grow px-4 py-4 text-sm font-semibold border border-[#EBEBEB] bg-[#FAFAFA] rounded-lg text-deep-blue"
                  />
                  <button className="h-full text-sm font-semibold btn btn-primary rounded-xl">
                    Send Invite
                  </button>
                </div> */}
                <div className="space-y-4">
                  {referralData.getReferralsByReferrer.length > 0 ? (
                    referralData.getReferralsByReferrer.map((referral: Referral, index: number) => (
                      <div
                        key={index}
                        className="flex items-center justify-between py-2 rounded-lg"
                      >
                        <div className="flex items-center space-x-4">
                          <div className="flex items-center justify-center w-8 h-8 bg-[#FAFAFA] border border-[#EBEBEB] text-lg font-semibold rounded-full text-deep-blue">
                            {referral?.referredInvestor?.user.email.charAt(0).toUpperCase()}
                          </div>
                          <span className="text-sm font-bold text-deep-blue">
                            {referral?.referredInvestor?.user.email}
                          </span>
                        </div>
                        <span
                          className="text-sm font-semibold text-[#34C759] bg-[rgba(61,_133,_5,_0.07)] p-2 rounded-md">
                          Invitation Accepted
                        </span>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-600">No referrals yet.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        {showRedeemRewards && rewardBalance && (
          <div className="fixed inset-0 z-[9999] flex items-center justify-center bg-gray-900 bg-opacity-50">
            <div className="w-1/3 px-6 pt-10 pb-6 bg-white rounded-lg shadow-lg">
              <div className="flex items-center justify-between mb-4 pb-4 border-b border-[#E5E5E5]">
                <h2 className="text-xl font-bold text-deep-blue">
                  {isRedeemed ? 'Reward Redeemed!' : 'Redeem Rewards'}
                </h2>
                <button
                  onClick={() => setShowRedeemRewards(false)}
                  className="text-2xl text-gray-500 hover:text-gray-800"
                >
                  &times;
                </button>
              </div>

              {isRedeemed ? (
                <p className="font-semibold text-center text-green-500">
                  Your reward of £{redeemAmount} has been successfully redeemed for tokens!
                </p>
              ) : (
                <>
                  {/* Redeem Rewards Form */}
                  <p className="mb-4 text-deep-blue">
                    Redeem £100 for 1 token.
                  </p>

                  <p className="text-sm text-gray-500">
                    Total Reward Balance: <span className="font-semibold">£{rewardBalance}</span>
                  </p>

                  <div className="flex items-center justify-center w-full gap-4 mt-4 mb-6">
                    <button
                      className="px-4 py-2 bg-gray-200 rounded-lg"
                      onClick={() => handleAmountChange(-100)}
                      disabled={redeemAmount <= 100}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      className="w-20 p-2 text-center border border-gray-300 rounded-lg focus:outline-none"
                      value={redeemAmount}
                      min={100}
                      max={rewardBalance}
                      readOnly
                    />
                    <button
                      className="px-4 py-2 bg-gray-200 rounded-lg"
                      onClick={() => handleAmountChange(100)}
                      disabled={redeemAmount + 100 > rewardBalance}
                    >
                      +
                    </button>
                  </div>

                  {errorMessage && (
                    <div className="mb-4 text-center text-red-500">{redeemError}</div>
                  )}

                  <button
                    onClick={redeemRewards}
                    className="w-full px-4 py-6 font-semibold btn btn-primary"
                    disabled={redeemAmount > rewardBalance}
                  >
                    Redeem
                  </button>
                </>
              )}
            </div>
          </div>
        )}

        <h2 className="mt-8 mb-4 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
          Transactions
        </h2>
        <div className="w-full transactions card">
          <table className="w-full">
            <thead>
              <tr>
                <th className="font-semibold text-left border-b">Type</th>
                <th className="font-semibold text-left border-b">Status</th>
                <th className="font-semibold text-left border-b">Date</th>
                <th className="font-semibold text-left border-b">Amount</th>
              </tr>
            </thead>
            <tbody>
              {loadingTransactions && !backgroundRefresh ? (
                <TransactionsSkeleton
                  width={responsiveSize.width}
                  height={100}
                />
              ) : (
                transactionData &&
                transactionData.getTransactions.length === 0 && (
                  <tr>
                    <td className="text-center empty" colSpan={4}>
                      No transactions
                    </td>
                  </tr>
                )
              )}
              {transactionData &&
                transactionData.getTransactions.length > 0 &&
                transactionData.getTransactions.map((transaction: any) => (
                  <tr key={transaction.code}>
                    <td data-test="transaction-type" className="text-left">{transaction.type}</td>
                    <td data-test="transaction-status" className="text-left">{transaction.status}</td>
                    <td data-test="transaction-date" className="text-left">
                      {format(transaction.createdAt, "PPP")}
                    </td>
                    <td data-test="transaction-amount" className="text-left">
                      {toCurrencyDisplay(transaction.amount)}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>

        <h2 className="mt-8 mb-4 font-sans text-2xl font-bold leading-normal tracking-normal text-deep-blue">
          Payment Methods
        </h2>
        <div className="flex flex-row flex-wrap gap-6 deposit-methods m-flex-col">
          {loadingPaymentMethods ? (
            <VaultSkeleton width={responsiveSize.width} height={200} />
          ) : (
            paymentMethodData &&
            paymentMethodData.listPaymentMethods.length > 0 && (
              <div
                className="flex gap-4 deposit-method"
                data-test="deposit-method"
                style={{ flexWrap: "wrap", flex: "1 1 50%" }}
              >
                {paymentMethodData &&
                  paymentMethodData.listPaymentMethods.length > 0 &&
                  paymentMethodData.listPaymentMethods.map(renderPaymentMethod)}
              </div>
            )
          )}
          <div className="deposit-method">
            {loadingPaymentMethods ? (
              <VaultSkeleton width={responsiveSize.width} height={200} />
            ) : (
              <div className="deposit-method-content card">
                <p>
                  Add a card or bank to enjoy instant deposits from anywhere in
                  the world
                </p>
                <a
                  href="#add-card"
                  className="btn btn-secondary btn-full"
                  data-test="add-card"
                  onClick={() => setShowPaymentModal(true)}
                >
                  Add new card
                </a>
              </div>
            )}
          </div>
        </div>
      </section >
    </>
  );
}
